import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import io from "socket.io-client";
import { API, ws } from '../../config'
import ChatAdmin from '../../components/admin/chatAdmin'
import IframeMan from '../../components/admin/iframeMan'
import TriviaAdmin from '../../components/trivia/triviaAdmin2'
import UploadFileImg from '../../components/uploadFile/uploadFileImg'
import SlideCheck from '../../components/inputs/slideCheck/slideCheck'

import './admin.css'
import MemoriesMan from '../../components/admin/memoriesMan';

const socket = io.connect(ws, { transports: ['websocket'] });

let Admin = ({ history }) => {
  const [cookie, setCookie, removeCookie] = useCookies({})
  const [registers, setRegisters] = useState([])
  const [online, setOnline] = useState([])
  const [slideCheck, setSlideCheck] = useState(false)
  const [consult, setConsult] = useState('')
  const [specific, setSpecific] = useState({})

  let user = null

  useEffect(() => {
    socket.emit("new-user", 'adminload');

    /* --------------------------------------------------- */

    // User Connection and Disconnection
    socket.on("user-connected", (user) => {
      console.log(`${user.name} ha entrado a la sala`);
    });

    socket.on("user-disconnected", (user) => {
      if (user !== null) {
        console.log(`Usuario ${user.name} se ha desconectado`);
      }
    });

    socket.on("user-list", (users) => {
      if (user !== null) {
        let listUsers= []
        for (const user in users) {
          listUsers.push(users[user])
        }
        setOnline(listUsers)
      }
    });
  }, [])


  if (!cookie.adminAccess) {
    history.push('/')
  } else {
    user = cookie.adminAccess.data
  }

  /* =================================================== */
  /* ===========USUARIOS REGISTRADOS =================== */

  let handleClickRegisters = (evt) => {
    let conf = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
    }

    fetch(`${API}/register/`, conf)
      .then(res => res.json())
      .then(res => {
        console.log(res);
        setRegisters(res)
      })
  }

  let handleConsult = (evt) => {
    evt.preventDefault()

    fetch(`${API}/register/${consult}`)
      .then(res => res.json())
      .then(res => {
        console.log(res);
        setSpecific(res)
      })
  }

  /* ===========USUARIOS REGISTRADOS =================== */
  /* =================================================== */

  const logOut = () => {
    removeCookie('adminAccess')
    removeCookie('accesUser')
    removeCookie('clientAdminAccess')

    if (cookie.adminAccess) {
      let userID = cookie.adminAccess.data.user._id


      let conf = {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
      }

      fetch(`${API}/auth/logout/${userID}`, conf)
        .then(res => res.json())
        .then(res => {
          console.log(res);
          history.push('/')
        })
    }
  }

  return (
    <div className="adminContainer">
      <div className="backCont">
        <button className='backBtn close' onClick={logOut}>
          <div className="arrow-left">
            &#10008;
          </div>
          <p>Cerrar Sesión</p>
        </button>
      </div>

      <div className="card chatAdmin">
        <div className="title">
          <h2>Foro</h2>
          <SlideCheck state={{ slideCheck: [slideCheck, setSlideCheck] }} />
        </div>
        {
          slideCheck &&
          <ChatAdmin socket={socket} user={user}/>
        }
      </div>

      {
        cookie.AsoAccess
        ? <React.Fragment></React.Fragment>
        : <div className="card agendaMan">
            <div className="title">
              <h2>Agenda</h2>
            </div>
            <div className="uploadimages">
              <UploadFileImg imageTitle="imgDia1" id="imgDia1" text="Selecciona Imagen dia 1"/>
              <UploadFileImg imageTitle="imgDia2" id="imgDia2" text="Selecciona Imagen dia 2"/>
            </div>
          </div>
      }

      <div className="card onlineCont">
        <div className="title">
          <h2>Usuarios online</h2>
        </div>
        <div className="listCont">
          <div className="action">
            {
              online.length === 1
                ? <h3>{`${online.length} Usuario online`}</h3>
                : <h3>{`${online.length} Usuarios online`}</h3>
            }
            <form onSubmit={e => { handleConsult(e) }}>
              <input type="text" value={consult} onChange={e => {setConsult(e.target.value)}}/>
              <button type="submit" className='adminButton'>Consultar</button>
            </form>
          </div>
          <div className="listRegisters">
            {
              online.map((ele, idx) => {
                return (
                  <div className="userInfo" key={idx}>
                    <p>{ele.name}</p>
                    <p>{ele.uID}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
        {
          specific.name &&
          <div className="especificUser">
            <p>{specific.name}</p>
            <p>{specific.email}</p>
            <p>{specific.dni}</p>
          </div>
        }
      </div>

      <div className="card gamesCont">
        <div className="title">
          <h2>Actividades</h2>
        </div>
        <TriviaAdmin socket={socket} />
      </div>

      <div className="card registers">
        <div className="title">
          <h2>Usuarios registrados</h2>
        </div>
        <div className="listCont">
          <div className="action">
            {
              registers.length === 1
                ? <h3>{`${registers.length} Usuario registrado`}</h3>
                : <h3>{`${registers.length} Usuarios registrados`}</h3>
            }
            <button onClick={e => { handleClickRegisters(e) }} className='adminButton'>Cargar lista</button>
          </div>
          <div className="listRegisters">
            {
              registers.map((ele) => {
                return (
                  <div className="userInfo" key={ele._id}>
                    <p>{ele.name}</p>
                    <p>{ele.email}</p>
                    <p>{ele.mobile}</p>
                    <p>{ele.document}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>

      <div className="card memories">
        <div className="title">
          <h2>Memorias</h2>
        </div>
        <MemoriesMan socket={socket}/>
      </div>

      <div className="card streaming">
        <IframeMan socket={socket}/>
      </div>

    </div>
  )
}

export default Admin
