import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Welcome from './pages/welcome/welcome'
import Login from './pages/login/login'
import Lobby from './pages/lobby/lobby'
import Room from './pages/room/room'
import Register from './pages/register/register'
import Memories from './pages/memories/memories'
import Admin from './pages/admin/admin'
import ClientAdmin from './pages/client-Admin/clientAdmin'
import Page404 from './pages/page404/page404'

let Routes = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Welcome}/>
        <Route exact path="/login" component={Login}/>
        <Route exact path="/register" component={Register}/>
        <Route exact path="/lobby" component={Lobby}/>
        <Route exact path="/admin" component={Admin}/>
        <Route exact path="/client-admin" component={ClientAdmin}/>
        <Route exact path="/room" component={Room} />
        <Route exact path="/memories" component={Memories} />
        <Route component={Page404} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
