import React, { useState, useEffect } from 'react'
import QuestionCard from './questionCard/questionCard'
import Counter from './counter/counter'
import io from "socket.io-client";
import alert from '../alert'
import { API, ws } from '../../config'
import Chart from 'chart.js'
import './triviaAdmin.css'
import InputNumber from '../inputs/input-number/inputNumber';

const socket = io.connect(ws, { transports: ['websocket'] });


const TriviaAdmin = () => {
  const [trivia, setTrivia] = useState([{ text: '', answerId: '0-0', choices: [{ text: '', _id: '0-0' }] }])
  const [createdTriviaId, setCreatedTriviaId] = useState('')
  const [createdTrivia, setCreatedTrivia] = useState(false)
  //const [time, setTime] = useState(false)
  //const [winnersObj, setWinnersObj] = useState({})
  const [listOfWinners, setListOfWinners] = useState([])
  const [countTimes, setCountTimes] = useState(0)
  const [timeToquestion, setTimeToquestion] = useState('')
  let time = false
  let winnersObj = {}

  /* ================ DRAW CHART =========================== */
  var labels = [];
  var votes = [];
  let chart
  if (document.getElementById('myChart') && !createdTrivia) {

    var ctx = document.getElementById('myChart').getContext('2d');
    chart = new Chart(ctx, {
      // The type of chart we want to create
      type: 'bar',

      // The data for our dataset
      data: {
        labels: labels,
        datasets: [{
          label: 'Votos',
          backgroundColor: '#00677F',
          borderColor: '#00677F',
          data: votes,
        }]
      },
      // Configuration options go here
      options: {
        title: {
          display: true,
          text: 'Respuestas',
          padding: 10,
          lineHeight: 1,
          fontSize: 16
        },
        scales: {
          yAxes: [{
            gridLines: {
              color: '#EEE',
            },
            ticks: {
              beginAtZero: true,
              fontColor: '#AAA',
              stepSize: 0.5,
            }
          }],
          xAxes: [{
            gridLines: {
              color: '#EEE',
            },
          }]
        },
        legend: {
          display: false,
        },
        events: []
      }
    });
  }

  function updatingChart(lab, vot, tit) {
    let vots = []
    vot.forEach(e => vots.push(e[0]))
    if (chart) {
      chart.options.title.text = tit;
      chart.data.labels = lab;
      chart.data.datasets[0].data = vots;
      chart.update()
    }
    var ctx = document.getElementById('myChart').getContext('2d');
    chart = new Chart(ctx, {
      // The type of chart we want to create
      type: 'bar',

      // The data for our dataset
      data: {
        labels: lab,
        datasets: [{
          label: 'Votos',
          backgroundColor: '#00677F',
          borderColor: '#00677F',
          data: vots,
        }]
      },

      // Configuration options go here
      options: {
        title: {
          display: true,
          text: tit,
          padding: 10,
          lineHeight: 1,
          fontSize: 16
        },
        scales: {
          yAxes: [{
            gridLines: {
              color: '#EEE',
            },
            ticks: {
              beginAtZero: true,
            }
          }],
          xAxes: [{
            gridLines: {
              color: '#EEE',
            },
          }]
        },
        legend: {
          display: false,
        },
        events: []
      }
    });
  }

  useEffect(() => {
    socket.on("countdown", (data, id, triv, time) => {
      const display = document.getElementById(`counterR${triv._id}`);
      const duration = time;
      if (data === 'start') {
        startTimer(duration, display, id, triv)
      }
    });
    socket.on("trivia-score", (score, triviaID, id) => {
      var conf = {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        },
      };

      fetch(`${API}/trivia/score/${triviaID}`, conf)
        .then(res => {
          if (res.status !== 200) {
            console.log('Error cargando ganadores por trivia');
          } else {
            return res.json();
          }
        })
        .then(dt => {
          if (dt !== undefined) {
            var questions = dt;
            var localCount = 0;

            setCountTimes(countTimes + 1)

            questions.forEach((question) => {
              localCount++;
              question["winners"].forEach((winner) => {
                if (!winnersObj[winner.user._id]) {
                  let newWinnersObj = { ...winnersObj }
                  newWinnersObj[winner.user._id] = {
                    'name': winner.user.name,
                    'score': winner.score,
                    'answerID': winner._id,
                  }
                  //setWinnersObj(newWinnersObj)
                  winnersObj = newWinnersObj
                } else {
                  let newWinnersObj = { ...winnersObj }
                  newWinnersObj[winner.user._id] = {
                    name: winner.user.name,
                    score: winnersObj[winner.user._id].score + winner.score,
                    answerID: winner._id,
                  }
                  winnersObj = newWinnersObj
                  //setWinnersObj(newWinnersObj)
                }
              })
            })
            if (createdTrivia) {
              const toDelete = createdTrivia.find(element => element._id === id)
              const newArr = createdTrivia.filter(element => element !== toDelete)
              setCreatedTrivia(newArr)
              socket.emit('winnersObj', winnersObj);
              if (newArr.length === 0) {
                winnersObj = {}
                //setWinnersObj({})
              }
            }
            /* var actionsCards = document.getElementsByClassName('actions');

            if (createdTrivia.length > 0) {
              console.log('Suiguiente pregunta');
              actionsCards[0].remove();
              socket.emit('winnersObj', winnersObj);
              console.log("SCORES", score);
            } else {
              console.log('Ya se enviaron todas las preguntas');
              socket.emit('winnersObj', winnersObj);
              actionsCards[0].remove();
              winnersObj = {}
            } */
          }
        })

    });

    socket.on("chart", (triviaIDToChart, questionIDToChart) => {
      let conf = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      };

      fetch(`${API}/trivia/answer/${triviaIDToChart}/${questionIDToChart}`, conf)
        .then(res => res.json())
        .then(ansData => {
          // Get count for each choice
          const stad = {};
          ansData.forEach((answer) => {
            if (!stad[answer.choiceText]) {
              stad[answer.choiceText] = 1;
            } else {
              stad[answer.choiceText]++;
            }
          });

          // Turn to Array
          const stadArray = [];
          for (const choice in stad) {
            const singleObj = {};
            singleObj[choice] = stad[choice];
            stadArray.push(singleObj);
          }

          stadArray.forEach((data) => {
            labels.push(Object.keys(data))
            votes.push(Object.values(data))
          })

          updatingChart(labels, votes, "Pregunta")
          labels = [];
          votes = [];
        })
    })

    socket.on("winnersObj", (data) => {
      winnersObj = data

      if (Object.keys(winnersObj).length > 0) {
        setListOfWinners(Object.values(winnersObj))

        /* Object.values(winnersObj).forEach((winner) => {
          listOfWinners.push({
            name: winner.name,
            score: winner.score,
          })
          console.log(winner);
          let newList = [...listOfWinners]
          newList.push({
            name: winner.name,
            score: winner.score,
          })
          setListOfWinners(newList)
        }) */

        //setListOfWinners(listOfWinners.sort(((a, b) => b.score - a.score)))
        if (createdTrivia.length === 0) {
          let results = Object.values(winnersObj)
          if (results.length > 5) {
            results = results.splice(0, 5)
          }
          socket.emit('gold', results)
        }
      }
    })
    return () => { socket.off() }
  }, [createdTrivia, countTimes, winnersObj]);



  const plusHandler = () => {
    setTrivia([...trivia, { text: '', answerId: `${trivia.length}-0`, choices: [{ text: '', _id: `${trivia.length}-0` }] }])
  }

  const lessHandler = () => {
    if (trivia.length > 1) {
      let triviaClone = [...trivia]
      triviaClone.splice(trivia.length - 1, 1)
      setTrivia(triviaClone)
    }
  }

  const plusCardHandler = (index) => {
    let triviaClone = [...trivia]
    triviaClone[index].choices.push({ text: '', _id: `${index}-${triviaClone[index].choices.length}` })
    setTrivia(triviaClone)
  }

  const lessCardHandler = (index) => {
    if (trivia.length > 0 && trivia[index].choices.length > 1) {
      let triviaClone = [...trivia]
      triviaClone[index].choices.splice(triviaClone[index].choices.length - 1, 1)
      setTrivia(triviaClone)
    }
  }

  const onChangeQuestion = (e, i) => {
    let triviaClone = [...trivia]
    triviaClone[i].text = e.target.value
    setTrivia(triviaClone)
  }

  const onChangeCard = (e, index, i) => {
    let triviaClone = [...trivia]
    triviaClone[index].choices[i].text = e.target.value
    setTrivia(triviaClone)
  }

  const createTrivia = () => {
    cancelTrivia()
    let send = true
    for (const item of trivia) {
      if (item.text.trim() === '' || item.choices.length === 0) {
        send = false
        alert("Faltan campos por llenar", false)
        break
      }
      for (const i of item.choices) {
        if (i.text.trim() === '') {
          send = false
          alert("Faltan campos por llenar", false)
          break
        }
      }
    }
    updatingChart(labels, votes, "Pregunta")
    if (send) {
      let conf = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({ trivia: trivia }),
      };

      fetch(API + "/trivia/new", conf)
        .then(res => res.json())
        .then((dataTrivia) => {
          console.log("TRIVIA SENT", dataTrivia);
          setCreatedTriviaId(dataTrivia._id)
          setCreatedTrivia(dataTrivia.trivia);
          socket.emit('start-game', trivia.length);
          setTrivia([{ text: '', answerId: '0-0', choices: [{ text: '', _id: '0-0' }] }])
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  const onChangeSelection = (e, index) => {
    let triviaClone = [...trivia]
    triviaClone[index].answerId = e.target.value
    setTrivia(triviaClone)
  }

  const sendQuestion = (triviaId, question) => {
    time = true
    //setTime(true)
    socket.emit("trivia-new-question", triviaId, question, timeToquestion);
  }

  const stopQuestion = (triviaId, questionId) => {
    if (time) {
      time = false
      //setTime(false)
      socket.emit("trivia-stop", triviaId, questionId);
    }
  }

  const startTimer = (duration, display, id, trivia) => {
    var timer = duration, minutes, seconds;
    const inter = setInterval(() => {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      display.textContent = minutes + ":" + seconds;
      if (timer-- === 0) {
        console.log('Time over');
        timer = 0;
        clearInterval(inter)
        stopQuestion(id, trivia._id);
      }
    }, 1000);
  }

  const cancelTrivia = () => {
    winnersObj = {}
    setCreatedTrivia(false)
    setCountTimes(0)
    setListOfWinners([])
    updatingChart([], [], 'Encuesta')
    socket.emit('cancel-trivia', 'cancel')
  }

  return (
    <div className='adminTriviaContainer'>
      <h2>Trivia</h2>
      <InputNumber className="inputTime" id="inputTime" placeHolder="Ingrese el tiempo en Segundos" state={{ number: [timeToquestion, setTimeToquestion] }}/>
      <h4>Agregar o quitar preguntas:</h4>
      <div className='adminTriviaOptions'>
        <button className='adminTriviaPlus' onClick={plusHandler}>+</button>
        <button className='adminTriviaLess' onClick={lessHandler}>-</button>
      </div>
      <div className='adminTriviaCards'>
        {
          trivia.map((card, index) => (
            <form className='adminTriviaCard' key={index}>
              <input type="text" placeholder='PREGUNTA' value={card.text} onChange={(e) => onChangeQuestion(e, index)} />
              <h4>Agregar o quitar respuestas:</h4>
              <div className='adminTriviaCardOptions'>
                <button className='adminTriviaPlus' type='button' onClick={(e) => plusCardHandler(index)}>+</button>
                <button className='adminTriviaLess' type='button' onClick={(e) => lessCardHandler(index)}>-</button>
              </div>
              {
                card.choices.map((item, i) => (
                  <div key={i} className='answersCont'>
                    <p>{item._id}</p>
                    <input type="text" placeholder={`Respuesta-${i}`} value={item.text} onChange={(e) => onChangeCard(e, index, i)} />
                  </div>
                ))
              }
              <h4>Elige la respuesta correcta:</h4>
              <select value={card.answerId} onChange={(e) => onChangeSelection(e, index)}>
                {
                  card.choices.map((item, i) => (
                    <option value={item._id} key={i}>{item.text}</option>
                  ))
                }
              </select>
            </form>
          ))
        }
      </div>
      <button className='adminTriviaSend' onClick={createTrivia} >Enviar</button>
      <span className="separator"></span>
      <div className="chartContainer">
        <canvas id="myChart" width="450" height="200"></canvas>
      </div>
      <div className='adminTriviaCreated'>
        {createdTrivia ?
          createdTrivia.map((triv, i) => (
            <div key={i} className='adminTriviaCreatedCard'>
              <h2>{triv.text}</h2>
              <div className='adminTriviaCardOptions'>
                <button className='adminTriviaCreatedButton1' onClick={() => sendQuestion(createdTriviaId, triv)} disabled={time}>Enviar</button>
                <button className='adminTriviaCreatedButton2' onClick={() => stopQuestion(createdTriviaId, triv._id)}>Detener</button>
              </div>
              <h2 id={`counterR${triv._id}`}>{timeToquestion}</h2>
            </div>
          )) : ''
        }
      </div>
      {
        createdTrivia ? <button className='adminTriviaCancel' onClick={cancelTrivia}>Cancel Trivia</button> : ''
      }
      {listOfWinners.length > 0 ?
        <h1>List of winners</h1> : ''
      }
      {(listOfWinners && listOfWinners.length) ? listOfWinners.map((winner, i) => (
        <div className="winner" key={i}>
          <h4>{winner.score} </h4>
          <div style={{ width: '1vh' }}></div>
          <h3> {winner.name}</h3>
        </div>
      )) : ''}
    </div>
  )
}

export default TriviaAdmin
