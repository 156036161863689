import { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import InputEmail from '../../components/inputs/input-email/inputEmail'
import InputName from '../../components/inputs/input-text/inputText-name'
import Loader from 'react-loader-spinner'
import { useCookies } from 'react-cookie'
import { API } from '../../config'

import './register.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

let Login = (props) => {
  const [name, setName] = useState('')
  const [mail, setMail] = useState('')
  const [loanding, setLoanding] = useState(false)
  const [status, setStatus] = useState('pendiente') //error, registrado
  const [message, setMessage] = useState('')
  const [cookie, setCookie] = useCookies({})

  let localDate = new Date()
  let days = [17, 18]

  useEffect(() => {

    return () => {
      // UnMount
    }
  }, [status])

  let expires = new Date()
  let hours = process.env.REACT_APP_COOKIE_EXPIRE_TIME_HOURS
  expires.setTime(expires.getTime() + (hours * 60 * 60 * 1000))

  let handleSubmit = (evt) => {
    evt.preventDefault()
    setLoanding(true)

    let body = {
      name: name,
      email: mail,
      mobile: "phone",
      document: "document",
      tyc: true,
    }

    let conf = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify(body),
    };

    fetch(`${API}/register/`, conf)
      .then(res => {
        if (res.status === 404) {
          console.log("Error, endpoint no encontrado");
        } else {
          return res.json()
        }
      })
      .then(res => {
        console.log(res);

        if (res) {
          if (res.error) {
            setStatus('error')
            if (res.error === 'DuplicatedError') {
              setMessage('¡El usuario ya está registrado!')
            }
          } else {
            if (mail === "admin@colgate.com") {
              setCookie('adminAccess', JSON.stringify(res), { path: "/", expires })
            } else {
              setCookie('accesUser', JSON.stringify(res), { path: "/", expires })
            }
            setStatus("registrado")
            setMessage('¡Perfecto! Muchas gracias por registrarte, ya puedes ingresar aquí')
          }
        }
        setLoanding(false)
      }).catch((err) => {
        console.log(err);
        setLoanding(false)
      })
  }

  if (status !== 'registrado') {

    return (
      <div className="registerContainer">
        <div className="imgBg"></div>

        <div className={`dateLogo mar${days.indexOf(localDate.getDate()) >= 0 ? localDate.getDate() : 'zo'}`}></div>

        <div className="inputs">
          {
            !loanding
            ? <form className="form" onSubmit={e => {handleSubmit(e)}}>
                <div className="inputsCont">
                  <InputName id="inputName" className="inputnName" state={{ name: [name, setName] }} />
                  <InputEmail id="userEmail" placeHolder="" state={{ mail: [mail, setMail] }}/>
                </div>
                <button type="submit">
                  Registrarme
                  <div className="arrow">
                    &#10095;
                  </div>
                </button>
              </form>
            : <Loader
                className="loadingCircle"
                type="TailSpin"
                color="#FFF"
                height={100}
                width={100}
              />
          }
          {
            status === 'error' &&
            <div className="boxmessage">
              <h2>{message}</h2>
            </div>
          }
        </div>
      </div>
    )
  } else {
    return (
      <div className="registerContainer">
        {/* DEmas elementos de la vista */}
        <div className="imgBg"></div>

        <div className="title"></div>

        <div className="inputs">
          <div className="boxmessage">
            <h2>{message}</h2>
            <Link className="button" to={"/login"}>Ingresar</Link>
          </div>
        </div>
      </div>
    )
  }
}

export default Login
