import { useState } from "react"

let Circle = (props) => {
  const { radius, stroke, progress } = props

  const normalizedRadius = radius - stroke * 2
  const circumference = normalizedRadius * 2 * Math.PI
  const strokeDashoffset = circumference - progress / 100 * circumference;

  return (
    <div className="svg">
      <svg className="svgCircle" height={radius * 2} width={radius * 2}>
        <circle
          className="circleForm"
          stroke="#083E66"
          fill="transparent"
          strokeWidth={ stroke }
          strokeDasharray={ circumference + ' ' + circumference }
          style={ { strokeDashoffset } }
          strokeWidth={ stroke }
          r={ normalizedRadius }
          cx={ radius }
          cy={ radius }
        />
      </svg>
    </div>
  )
}

export default Circle
