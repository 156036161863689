import { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'
import { API, ws } from '../../config'
import io from "socket.io-client";
import HelpBtn from '../../components/btnHelp/btnHelp'
import './memories.css'

const socket = io.connect(ws, { transports: ['websocket'] });

let Memories = ({ history }, ...rest) => {
  const [cookie, setCookie, removeCookie] = useCookies({})
  const [btnDia1, setBtnDia1] = useState(true)
  const [btnDia2, setBtnDia2] = useState(false)
  const [iframClient, setIframeClien] = useState(null)

  let user = null;
  let memoriesDia1 = [1, 2, 3, 4, 5, 6]
  let memoriesDia2 = [1, 2, 3, 4, 5]

  useEffect(() => {
    let conf = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
    };

    fetch(`${API}/iframe/selectBtn-1-Day1`, conf)
      .then(res => res.json())
      .then(res => {
        setIframeClien(res.iframe)
      })

    return () => {
    }
  }, []);

  if (cookie.accesUser) {
    user = cookie.accesUser.data
  } else if (cookie.adminAccess) {
    user = cookie.adminAccess.data
  }

  if (!user) {
    history.push('/')
    return (<div></div>)
  }

  //================================================================ //

  const clickBtndia1 = (evt) => {
    setBtnDia1(true)
    setBtnDia2(false)
  }

  const clickBtndia2 = (evt) => {
    setBtnDia1(false)
    setBtnDia2(true)
  }

  const logOut = () => {
    removeCookie('adminAccess')
    removeCookie('accesUser')

    if (cookie.accesUser) {
      let userID = cookie.accesUser.data.user._id


      let conf = {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
      }

      fetch(`${API}/auth/logout/${userID}`, conf)
        .then(res => res.json())
        .then(res => {
          console.log(res);
          socket.disconnect()
          history.push('/')
        })
    }
  }

  const loadIframe = (evt) => {
    let listNames = evt.target.className.split('-')
    let day = listNames[1][listNames[1].length - 1]
    let numberOfIframe = listNames[listNames.length - 1]

    console.log(day);

    let conf = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
    };

    fetch(`${API}/iframe/selectBtn-${numberOfIframe}-Day${day}`, conf)
      .then(res => res.json())
      .then(res => {
        console.log(res);
        setIframeClien(res.iframe)
      })
  }

  return (
    <div className="memoriesContainer">
      <div className="backCont">
        <Link className='backBtn roomBack' to="/lobby">
          <div className="arrow-left">
            &#10094;
          </div>
          <p>Regresar</p>
        </Link>

        <button className='backBtn close' onClick={logOut}>
          <div className="arrow-left">
            &#10008;
          </div>
          <p>Cerrar Sesión</p>
        </button>
      </div>

      <HelpBtn country="57" phone="3023180804" className="btnHelp" text="Mesa de ayuda"/>

      <div className="memCont">

        <div className="iframeCont" dangerouslySetInnerHTML={{__html: iframClient}}></div>

        <div className="controls">
          <div className="btnMenu">
            <button className={btnDia1 ? "btnMemoriasdia1 selected" : "btnMemoriasdia1"} onClick={e => {clickBtndia1(e)}}>Marzo 17</button>
            <button className={btnDia2 ? "btnMemoriasdia2 selected" : "btnMemoriasdia2"} onClick={e => {clickBtndia2(e)}}>Marzo 18</button>
          </div>
          {
            btnDia1
            ? <div className="dia1">
                {
                  memoriesDia1.map((ele) => {
                    return (
                      <div key={ele} onClick={e => {loadIframe(e)}} className={`miniatura-dia1-${ele}`}></div>
                    )
                  })
                }
              </div>
            : <div className="dia2">
                {
                  memoriesDia2.map((ele) => {
                    return (
                      <div key={ele} onClick={e => {loadIframe(e)}} className={`miniatura-dia2-${ele}`}></div>
                    )
                  })
                }
              </div>
          }
        </div>
      </div>
    </div>
  )
}

export default Memories
