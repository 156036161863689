import { useState } from "react"

let InputTextName = (props) => {
  const { name: [name, setName] } = { name: useState(''), ...(props.state || {}) }

  return (
    <input
      required
      type="text"
      className={props.className}
      id={props.id}
      value={name}
      placeholder={props.placeHolder || "Nombre"}
      onChange={e => {setName(e.target.value)}}
    />
  )
}

export default InputTextName
