import React, { useState, useEffect } from 'react'
import { Check, X } from 'react-feather'
import Chat from '../../components/room/chat'
import { API } from '../../config'

export default function ChatAdmin({ socket, user }) {
  const [messages, setMessages] = useState([]);
  const [listOfMsg, setListOfMsg] = useState([]);

  const firstGet = async() => {
      const conf = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json'
      },
    };
      try {
        const res = await (await fetch(`${API}/messages/`, conf)).json()
        setMessages(res)
      } catch (error) {
        console.log('Error cargando los mensajes');
      }
  }
  useEffect(() => {
    firstGet()
    setInterval(async() => {
      const conf = {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        },
      };
        try {
          const res = await (await fetch(`${API}/messages/`, conf)).json()
          setMessages(res)
        } catch (error) {
          console.log('Error cargando los mensajes');
        }
    }, 5000);
  }, [])


  const deleteMessage = async (msg, i) => {
    const conf = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json'
      },
    };
    try {
      const res = await fetch(`${API}/messages/${msg._id}`, conf)
      let newMsg = messages
      newMsg = newMsg.filter((e) => {
        return e !== msg
      })
      setMessages(newMsg)
    } catch (error) {
      console.log('Error cargando los mensajes');
    }
  }

  const sendMessage = (msg, i, e) => {
    const element = document.getElementById('response' + i);
    socket.emit('chat', { user: {name: msg.userId.name, id: msg.userId._id}, message: msg.message, response: element.value })
    element.value = ''
    deleteMessage(msg, i)
  }

  let timeConverter = (date) => {

    let localDate = new Date(date)
    localDate = localDate.toLocaleString()

    return (localDate)

  }


  return (
    <div className='chatAdmin'>
      <div className='chatBuffer adminChatBuffer'>
        {
          messages.map((msg, i) => {
            return (
              <div key={i} className='chatAdminCard' message={i}>
                <div className="incomeMsg">
                  <div className="userInfo">
                    <p className='chatUsername'>{msg.userId.name}</p>
                    <p className="chatTime">{timeConverter(Date.parse(msg.date))}</p>
                  </div>
                  <p className='chatMessage'>{msg.message}</p>
                  <input id={'response' + i} className='chatAdminText' type="text" placeholder='Escribe la respuesta' />
                </div>
                <div className='chatAdminButtons' style={{display: 'flex', flexDirection: 'row'}}>
                  <button onClick={(e) => sendMessage(msg, i, e)} className='adminSendButton'><Check/></button>
                  <button onClick={() => deleteMessage(msg, i)} className='adminDeleteButton'><X/></button>
                </div>
              </div>
            )
          })
        }
      </div>
      <div className="chatUserContainer">
        <Chat socket={socket} user={user} />
      </div>
    </div>
  )
}

