import { useState } from 'react'
import { API } from '../../config'
import SlideCheck from '../inputs/slideCheck/slideCheck'

import './upload.css'

let UploadFileImg = (props) => {
  const [imgToSave, setImgToSave] = useState(null)
  const [slideCheck, setSlideCheck] = useState(false)

  /* =================================================== */
  /* ======= AGREGAR IMAGENES A LA AGENDA ============== */

  const handleUploadFileImage = (evt) => {
    setImgToSave(evt.target.files[0])
  }

  const handleSubmit = (evt) => {
    evt.preventDefault()

    const dataCreate = new FormData()
    dataCreate.append('file', imgToSave)
    dataCreate.append("name", props.imageTitle)

    const dataUpload = new FormData()
    dataUpload.append('file', imgToSave)

    const confCreate = {
      method: 'POST',
      body: dataCreate,
    }

    const confUpload = {
      method: 'PATCH',
      body: dataUpload,
    }

    if (slideCheck) {
      fetch(`${API}/custom-images/`, confCreate)
        .then(res => res.json())
        .then(res => {
          console.log(res);
        })
    } else {
      fetch(`${API}/custom-images/${props.imageTitle}`, confUpload)
        .then(res => res.json())
        .then(res => {
          console.log(res);
        })
    }
  }


  /* ======= AGREGAR IMAGENES A LA AGENDA ============== */
  /* =================================================== */
  return (
    <form onSubmit={e => {handleSubmit(e)}}>
      <div className="containerForm">
        <input type="file" id={props.id} accept="image/png, image/jpeg" onChange={e => {handleUploadFileImage(e)}}/>
        <label htmlFor={props.id} className="inputFileLb">{props.text}</label>
        <SlideCheck state={{ slideCheck: [slideCheck, setSlideCheck] }} />
        <p style={{ margin: "auto 10px" }}>Crear</p>
        {
          imgToSave &&
          <div className="fileCont">
            <p className="fileName">{imgToSave.name}</p>
            <button className="remove" onClick={e => {setImgToSave(null)}}>X</button>
          </div>
        }
      </div>
      <button className="submit" type="submit">Enviar</button>
      <p className="titleImg">{props.imageTitle}</p>
    </form>
  )
}

export default UploadFileImg
