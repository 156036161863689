import { useState } from "react"

let InputEmail = (props) => {
  const { mail: [mail, setEmail] } = { email: useState(''), ...(props.state || {}) }

  return (
    <input className="inputEmail" required type="email" id={props.id} placeholder={props.placeholder || "Correo electrónico"} value={mail} onChange={e => {setEmail(e.target.value)}}/>
  )
}

export default InputEmail
