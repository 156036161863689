import { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import InputEmail from '../../components/inputs/input-email/inputEmail'
import InputNumber from '../../components/inputs/input-number/inputNumber'
import Loader from 'react-loader-spinner'
import { useCookies } from 'react-cookie'
import { API } from '../../config'
import HelpBtn from '../../components/btnHelp/btnHelp'

import './login.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

let Login = (props) => {
  const [mail, setMail] = useState('')
  const [number, setNumber] = useState('')
  const [loanding, setLoanding] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('pendiente') //error, correcto
  const [cookie, setCookie] = useCookies({})
  const [isAdmin, setIsadmin] = useState(false)
  const [isAso, setIsAso] = useState(false)

  let localDate = new Date()
  let days = [17, 18]

  useEffect(() => {

    return () => {
      // UnMount
    }
  }, [status])

  let expires = new Date()
  let hours = process.env.REACT_APP_COOKIE_EXPIRE_TIME_HOURS
  expires.setTime(expires.getTime() + (hours * 60 * 60 * 1000))

  let handleSubmit = (evt) => {
    if (evt) {
      evt.preventDefault()
    }

    setLoanding(true)

    let body = {
      user: mail,
      password: number.toString(),
    }

    let conf = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify(body),
    };

    fetch(`${API}/auth/login`, conf)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          let msgError = res.error.split(" ")
          let user = res.error.split(" ")
          msgError.pop()
          msgError = msgError[0] + msgError[1]

          if (msgError === "Notfound") {
            setMessage(`¡El usuario no está registrado!`)
          } else if (res.error === "Wrong Password") {
            setMessage(`¡La contraseña es incorrecta!`)
          }
          setStatus('error')
        } else {
          if (mail === process.env.REACT_APP_USER_ADMIN) {
            setCookie('adminAccess', JSON.stringify(res), { path: "/", expires })
            setIsadmin(true)
          } else if (process.env.REACT_APP_USER_ASOBANCIARIA === mail) {
            setCookie('clientAdminAccess', JSON.stringify(res), { path: "/", expires })
            setIsAso(true)
          } else {
            setCookie('accesUser', JSON.stringify(res), { path: "/", expires })
          }
          setStatus("correcto")
        }
        setLoanding(false)
      })

  }

  if (status === 'correcto' || cookie.accesUser) {
    return (
      <Redirect to="/lobby" />
    )
  }

  if (isAdmin || cookie.adminAccess) {
    return (
      <Redirect to="/admin/" />
    )
  } else if (isAso || cookie.clientAdminAccess) {
    return (
      <Redirect to="/client-admin" />
    )
  }

  return (
    <div className="loginContainer">
      <HelpBtn country="57" phone="3023180804" className="btnHelp" text="Mesa de ayuda"/>

      <div className="imgBg"></div>

      <div className={`dateLogo mar${days.indexOf(localDate.getDate()) >= 0 ? localDate.getDate() : 'zo'}`}></div>

      <div className="inputs">
        {
          !loanding
          ? <form className="form" onSubmit={e => {handleSubmit(e)}}>
              <div className="inputsCont">
                <InputEmail id="userEmail" placeHolder="" state={{ mail: [mail, setMail] }}/>
                <InputNumber id="inputNumber" placeHolder="CÉDULA" className="inputNumber" state={{ number: [number, setNumber] }} />
              </div>
              <button type="submit">
                Ingresar
                <div className="arrow">
                  &#10095;
                </div>
              </button>
            </form>
          : <Loader
              className="loadingCircle"
              type="TailSpin"
              color="#FFF"
              height={100}
              width={100}
            />
        }
        {
          status === 'error' &&
          <div className="boxmessage">
            <h2>{message}</h2>
            <p onClick={e => {setStatus("pendiente")}}>X</p>
          </div>
        }
      </div>
    </div>
  )
}

export default Login
