import React, { useState, useEffect } from "react";
import { Send } from 'react-feather';
import { API } from '../../config'
import alert from '../alert'

import './chat.css'

function Chat({ socket, user }) {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('')

  useEffect(() => {
    socket.on("chat", msg => {
      setMessages([...messages, msg]);
      const chatBox = document.querySelector('.chatBuffer');
      chatBox.scrollTop = chatBox.scrollHeight;
    });

    return () => { socket.off() }

  }, [messages]);

  let handleOnSubmit = (e) => {
    e.preventDefault();

    if (message.trim() === '' || !user) return

    const data = {
      message: message,
      userId: user.user._id,//CAMBIAR POR USER.ID
      response: ''
    }

    var conf = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    };

    fetch(`${API}/messages/`, conf)
      .then(res => {
        if (res.status === 400) {
          console.log('error mensaje')
        } else {
          return res.json();
        }
      })
      .then(dt => {
        if (dt !== undefined) {
          alert('Pregunta Enviada', true)
          setMessage('')
        }
      })

    /* --------------------------------------------------- */
  }

  return (
    <div className='chat'>
      <div className='chatBuffer'>
        {
          messages.map((msg, i) => {
            return(
              <div key={i} className={msg.user.id === user.user._id ? 'chatOwnCard' : 'chatCard'}>
                <div>
                  <p className='chatUsername'>{msg.user.name}</p>
                  <p className='chatMessage'>{msg.message}</p>
                </div>
                {
                  (msg.response && msg.response !== '')
                    ? <div className="response">
                        <p className='chatUsername'>respuesta</p>
                        <p className='chatMessage'>{msg.response}</p>
                      </div>
                    : ''
                }
              </div>
            )

          })
        }
      </div>
      <form className='chatForm' onSubmit={e => {handleOnSubmit(e)}}>
        <input onChange={(e) => setMessage(e.target.value)} type="text" className='chatInput' value={message} placeholder='Escribe tu pregunta' required/>
        <button type='submit' className='chatInputButton'><Send /></button>
      </form>
    </div>
  );
}

export default Chat;
