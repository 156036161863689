import { useEffect, useState } from 'react'
import { API, ws } from '../../config'

import './agenda.css'

let Agenda = (props) => {
  const [agenda1, setAgenda1] = useState(true)
  const [agenda2, setAgenda2] = useState(false)

  const [imgDia1, setImgDia1] = useState("")
  const [imgDia2, setImgDia2] = useState("")

  const clickAgenda1 = (evt) => {
    setAgenda1(true)
    setAgenda2(false)

    fetch(`${API}/custom-images/imgDia1`)
      .then(res => res.json())
      .then(res => {
        setImgDia1(API + res.imagePath)
      })
  }

  const clickAgenda2 = (evt) => {
    setAgenda1(false)
    setAgenda2(true)

    fetch(`${API}/custom-images/imgDia2`)
      .then(res => res.json())
      .then(res => {
        setImgDia2(API + res.imagePath)
      })
  }

  useEffect(() => {

    fetch(`${API}/custom-images/imgDia1`)
      .then(res => res.json())
      .then(res => {
        setImgDia1(API + res.imagePath)
      })

    return () => {
      // Unmount
    }
  }, [])

  return (
    <div className="agendaCont">
      <div className="btnMenu">
        <button className={agenda1 ? "btnAgenda1 dia1 selected" : "btnAgenda1 dia1"} onClick={e => {clickAgenda1(e)}}>Marzo 17</button>
        <button className={agenda2 ? "btnAgenda2 dia2 selected" : "btnAgenda2 dia2"} onClick={e => {clickAgenda2(e)}}>Marzo 18</button>
      </div>
      <div className="imgTitle"></div>
      {
        agenda1
        ? <div className="imgagendaDia" style={{ backgroundImage: `url(${imgDia1})` }}></div>
        : <div className="imgagendaDia" style={{ backgroundImage: `url(${imgDia2})` }}></div>
      }
    </div>
  )
}

export default Agenda
