import { useEffect, useState } from 'react'
import Circle from './circle'

import './counterAsobancaria.css'

let interval = null

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

let Counter = (props) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [counterValues, setCounterValues] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })
  const [progress, setProgress] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })
  const [finishDate, setFinishDate] = useState(new Date('March 17, 2021 08:00:00').getTime())

  let radius = 0
  let stroke = 0

  if (windowDimensions.width <= 768) {
    radius = windowDimensions.width /13.5
    stroke = windowDimensions.width / 150
  } else {
    radius = 50
    stroke = 6
  }

  useEffect(() => {
    interval = setInterval(() => updateCountdown(), 1000)

    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);

    return () => {
      clearInterval(interval)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const updateCountdown = () => {
    if (finishDate) {
      // Get the current time
      const currentTime = new Date().getTime();

      // Get the time remaining until the countdown date
      const distanceToDate = finishDate - currentTime;

      // Calculate days, hours, minutes and seconds remaining
      let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distanceToDate % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

      // For visual appeal, add a zero to each number that's only one digit
      const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

      if (numbersToAddZeroTo.includes(hours)) {
        hours = `0${hours}`;
      } else if (numbersToAddZeroTo.includes(minutes)) {
        minutes = `0${minutes}`;
      } else if (numbersToAddZeroTo.includes(seconds)) {
        seconds = `0${seconds}`;
      }

      // Set the state to each new time
      setCounterValues({ days: days, hours: hours, minutes, seconds });
      setProgress({
        days: Math.abs(Math.floor(((17 - days) / 17) * 100)),
        hours: Math.abs(Math.floor(((8 - hours) / 8) * 100)),
        minutes: Math.abs(Math.floor(((60 - minutes) / 60) * 100)),
        seconds: Math.abs(Math.floor(((60 - seconds) / 60) * 100))
      })

      console.log();

      if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
        clearInterval(interval)
        setCounterValues({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        })
        setProgress({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        })
      }
    }
  }

  return (
    <div className="container">
      <div className="contValue counterDays">
        <h2>Días</h2>
        <div className="circleContainer">
          <div className="bgCircle"></div>
          <Circle radius={radius} stroke={stroke} progress={progress.days}/>
          <h3>{counterValues.days}</h3>
        </div>
      </div>
      <div className="contValue counterHours">
        <h2>Horas</h2>
        <div className="circleContainer">
          <div className="bgCircle"></div>
          <Circle radius={radius} stroke={stroke} progress={progress.hours}/>
          <h3>{counterValues.hours}</h3>
        </div>
      </div>
      <div className="contValue counterMinutes">
        <h2>Minutos</h2>
        <div className="circleContainer">
          <div className="bgCircle"></div>
          <Circle radius={radius} stroke={stroke} progress={progress.minutes}/>
          <h3>{counterValues.minutes}</h3>
        </div>
      </div>
      <div className="contValue counterSeconds">
        <h2>Segundos</h2>
        <div className="circleContainer">
          <div className="bgCircle"></div>
          <Circle radius={radius} stroke={stroke} progress={progress.seconds}/>
          <h3>{counterValues.seconds}</h3>
        </div>
      </div>
    </div>
  )
}

export default Counter
