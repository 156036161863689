import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ModalComplete from '../../components/modalComplete/modalComp'
import HelpBtn from '../../components/btnHelp/btnHelp'

import './welcome.css'

let Welcome = (props) => {
  const [close, setClose] = useState(true)

  let localDate = new Date()
  let days = [17, 18]

  const escFunction = (event) => {
    if(event.keyCode === 27) {
      setClose(true)
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="welcomeContainer">
      <HelpBtn country="57" phone="3023180804" className="btnHelp" text="Mesa de ayuda"/>
      {
        !close && <ModalComplete state={{ close: [close, setClose] }}/>
      }

      <div className="imgBg"></div>

      <div className={`dateLogo mar${days.indexOf(localDate.getDate()) >= 0 ? localDate.getDate() : 'zo'}`}></div>

      <div className="inputs">
        <Link className="loginLink" to="/login" >
          Ingreso
          <div className="arrow">
            &#10095;
          </div>
        </Link>

        <button className="agenda" onClick={e => {setClose(false)}}>
          Programación
          <div className="arrow">
            &#10095;
          </div>
        </button>
      </div>
    </div>
  )
}

export default Welcome
