import React, { useState, useEffect } from 'react'
import QuestionCard from './questionCard/questionCard'
import io from "socket.io-client";
import { API, ws } from '../../config'
import Counter from './counter/counter'
import './triviaUser.css'

const socket = io.connect(ws, { transports: ['websocket'] });

const TriviaUser = ({ userID, ...rest }) => {

  const { triviaStatus: [triviaStatus, setTriviaStatus] } = { triviaStatus: useState(false), ...(rest.state || {})}
  const [state, setState] = useState(0)
  const [trivia, setTrivia] = useState({})
  const [triviaID, setTriviaID] = useState('')
  const [numOfQuestions, setNumOfQuestions] = useState(0)
  const [listOfWinners, setListOfWinners] = useState([])

  useEffect(() => {
    socket.on("start-game", nQuestions => {
      setState(1)
      setTriviaStatus(true)
      setNumOfQuestions(nQuestions)
      const chatBox = document.querySelector('.room');
      chatBox.scrollTop = chatBox.scrollHeight;
    });

    socket.on("trivia-question", (id, trivia) => {
      setState(2)
      setTrivia(trivia)
      setTriviaID(id)
    });

    socket.on('countdown', (data, id, trivia, time) => {
      const display = document.getElementById('counterR');
      const duration = time;
      if (data === 'start') {
        startTimer(duration, display, id, trivia)
      }
    })

    socket.on('gold', winners => {
      setListOfWinners(winners)
      setState(5)
    });

    socket.on('cancel-trivia', cancel => {
      setListOfWinners([])
      setState(0)
      setNumOfQuestions(0)
      setTrivia({})
      setTriviaID('')
      setTriviaStatus(false)
    });

    return () => { socket.off() }
  }, [trivia]);

  let startTimer = (duration, display, id, trivia) => {
    var timer = duration, minutes, seconds;
    var inter = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        /* display.textContent = minutes + ":" + seconds; */

        if (--timer <= 0) {
          console.log('Time over');
          sendAnswer("", '')
          timer = 0;
          clearInterval(inter);
        }
      }, 1000);
    }

  const sendAnswer = (choiceId, choiceText) => {

    const data = {
      triviaId: triviaID,
      questionId: trivia._id,
      choiceId: choiceId,
      userId: userID,
      choiceText: choiceText,
    };

    socket.emit("trivia-answer", data);
    // console.log("...Anwser sent", data);
    socket.emit('standby', 'Espera a que el tiempo termine');
    if (numOfQuestions === 1) {
      setState(4)
    } else {
      setNumOfQuestions(numOfQuestions - 1)
      setState(3)
    }
  }

  if (state === 0) return (<div></div>)

  if (state === 1) return (
    <div className='triviaUser1'>
      <h1>Por favor evalúe a el (los) conferencista (s):</h1>
    </div>
  )

  if (state === 2) return (
    <div className='triviaUser2'>
      <div className='triviaTimeAndQuestion'>
        {/* <h3 id='counterR'>00:30</h3> */}
        <h2>{trivia.text}</h2>
      </div>
      <div className='triviaAnswers'>
        {/* <h2>Respuestas</h2> */}
        <div className="answersCont">
          {trivia.choices ?
            trivia.choices.map((choice, i) => (
              <button key={i} className='triviaAnswer' onClick={() => sendAnswer(choice._id, choice.text)}>{choice.text}</button>
            )) : ''
          }
        </div>
      </div>
    </div>
  )

  if (state === 3) return (
    <div className='triviaUser1'>
      <h1>Evalúe al próximo conferencista</h1>
    </div>
  )

  if (state === 4) return (
    <div className='triviaUser1'>
      <h1>Gracias</h1>
    </div>
  )

  if (state === 5) return (
    <div className='triviaUser5'>
      {listOfWinners.length > 0 ?
        <h1>List of winners</h1> : ''
      }
      {(listOfWinners && listOfWinners.length) ? listOfWinners.map((winner, i) => (
        <div className="winner" key={i}>
          <h4>{winner.score} </h4>
          <div style={{ width: '1vh' }}></div>
          <h3> {winner.name}</h3>
        </div>
      )) : ''}
    </div>
  )

  return (
    <div className='triviaUser'>
      user
    </div>
  )
}

export default TriviaUser
