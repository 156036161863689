import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import io from "socket.io-client";
import { API, ws } from '../../config'
import ChatAdmin from '../../components/admin/chatAdmin'
import IframeMan from '../../components/admin/iframeMan'
import TriviaAdmin from '../../components/trivia/triviaAdmin2'
import UploadFileImg from '../../components/uploadFile/uploadFileImg'
import Stream from '../../components/room/stream';

import './admin.css'
import MemoriesMan from '../../components/admin/memoriesMan';

const socket = io.connect(ws, { transports: ['websocket'] });

let Admin = ({ history }) => {
  const [cookie, setCookie, removeCookie] = useCookies({})
  const [registers, setRegisters] = useState([])
  const [iframClient, setIframeClient] = useState('')

  let user = null

  useEffect(() => {

    // socket.emit('onload', 'onload'); //onConnect

    let conf = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
    };

    fetch(`${API}/iframe/iframeDB`, conf)
      .then(res => res.json())
      .then(res => {
        setIframeClient(res.iframe)
      })

    return () => {
      // Unmount
    }
  }, [])


  if (!cookie.clientAdminAccess) {
    history.push('/')
  } else {
    user = cookie.clientAdminAccess.data
  }

  /* =================================================== */
  /* ===========USUARIOS REGISTRADOS =================== */

  let handleClickRegisters = (evt) => {
    let conf = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
    }

    fetch(`${API}/register/`, conf)
      .then(res => res.json())
      .then(res => {
        console.log(res);
        setRegisters(res)
      })
  }

  /* ===========USUARIOS REGISTRADOS =================== */
  /* =================================================== */

  const logOut = () => {

    if (cookie.clientAdminAccess) {
      let userID = cookie.clientAdminAccess.data.user._id


      let conf = {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
      }

      fetch(`${API}/auth/logout/${userID}`, conf)
        .then(res => res.json())
        .then(res => {
          console.log(res);
          removeCookie('adminAccess')
          removeCookie('accesUser')
          removeCookie('clientAdminAccess')
          history.push('/')
        })
    }
  }

  return (
    <div className="adminContainer">
      <div className="backCont">
        <button className='backBtn close' onClick={logOut}>
          <div className="arrow-left">
            &#10008;
          </div>
          <p>Cerrar Sesión</p>
        </button>
      </div>

      <div className="card streamingClient">
        <div className="title">
          <h2>En vivo</h2>
        </div>
        <Stream socket={socket} iframe={iframClient} />
      </div>

      <div className="card chatAdmin">
        <div className="title">
          <h2>Foro</h2>
        </div>
        <ChatAdmin socket={socket} user={user}/>
      </div>

      {
        cookie.clientAdminAccess
        ? <React.Fragment></React.Fragment>
        : <div className="card agendaMan">
            <div className="title">
              <h2>Agenda</h2>
            </div>
            <div className="uploadimages">
              <UploadFileImg imageTitle="imgDia1" id="imgDia1" text="Selecciona Imagen dia 1"/>
              <UploadFileImg imageTitle="imgDia2" id="imgDia2" text="Selecciona Imagen dia 2"/>
            </div>
          </div>
      }

      {
        cookie.clientAdminAccess
        ? <React.Fragment></React.Fragment>
        : <div className="card onlineCont">
            <div className="title">
              <h2>Usuarios online</h2>
            </div>
          </div>
      }

      {
        cookie.clientAdminAccess
        ? <React.Fragment></React.Fragment>
        : <div className="card gamesCont">
            <div className="title">
              <h2>Actividades</h2>
            </div>
            <TriviaAdmin socket={socket} />
          </div>
      }

      {
        cookie.clientAdminAccess
        ? <React.Fragment></React.Fragment>
        : <div className="card registers">
            <div className="title">
              <h2>Usuarios registrados</h2>
            </div>
            <div className="listCont">
              <div className="action">
                {
                  registers.length === 1
                    ? <h3>{`${registers.length} Usuario registrado`}</h3>
                    : <h3>{`${registers.length} Usuarios registrados`}</h3>
                }
                <button onClick={e => { handleClickRegisters(e) }} className='adminButton'>Cargar lista</button>
              </div>
              <div className="listRegisters">
                {
                  registers.map((ele) => {
                    return (
                      <div className="userInfo" key={ele._id}>
                        <p>{ele.name}</p>
                        <p>{ele.email}</p>
                        <p>{ele.mobile}</p>
                        <p>{ele.document}</p>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
      }

      {
        cookie.clientAdminAccess
        ? <React.Fragment></React.Fragment>
        : <div className="card memories">
            <div className="title">
              <h2>Memorias</h2>
            </div>
            <MemoriesMan socket={socket}/>
          </div>
      }

      {
        cookie.clientAdminAccess
        ? <React.Fragment></React.Fragment>
        : <div className="card streaming">
            <IframeMan socket={socket}/>
          </div>
      }

    </div>
  )
}

export default Admin
