import './help.css'

let Help = (props) => {
  let phone = props.phone
  let country = props.country
  let urlHelp = `https://wa.me/${country}${phone}`

  return (
    <div className="supportContainer">
      <a className={props.className} href={urlHelp} target="_blank" rel="noreferrer">
        <div className="title">
          <div className="msgBox">{props.text}</div>
          <div className="circle"></div>
        </div>
        <div className="imgIcon"></div>
      </a>
    </div>
  )
}

export default Help
