import { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { API, ws } from '../../config'
import { Link } from 'react-router-dom'
import ModalComplete from '../../components/modalComplete/modalComp'
import Counter from '../../components/counter/counter'
import HelpBtn from '../../components/btnHelp/btnHelp'

import io from "socket.io-client";

import './lobby.css'

const socket = io.connect(ws, { transports: ['websocket'] });

let Lobby = ({history}) => {
  const [cookie, setCookie, removeCookie] = useCookies({})
  const [close, setClose] = useState(true)
  let localDate = new Date()
  let days = [17, 18]
  let user = null;

  const escFunction = (event) => {
    if(event.keyCode === 27) {
      setClose(true)
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  if (cookie.accesUser) {
    user = cookie.accesUser.data
    let userID = user.user._id

    let userInfo = {
      name: user.user.name,
      uID: user.user._id
    }

    console.log('Socket Emit');

    socket.emit('new-user', userInfo);

    socket.on('user-list', (users) => {
      var countUID = 0;
      for (const user in users) {
        if (users[user].uID === userID) {
          countUID++;
        }
      }

      if (countUID > 1) {
        removeCookie('accesUser')

        if (cookie.accesUser) {
          let userID = cookie.accesUser.data.user._id

          let conf = {
            method: 'PATCH',
            headers: {
              'Accept': 'application/json',
              'Content-type': 'application/json',
            },
          }

          fetch(`${API}/auth/logout/${userID}`, conf)
            .then(res => res.json())
            .then(res => {
              console.log(res);
              socket.disconnect()
              history.push('/')
            })
        }
      }
    });

  } else if (cookie.adminAccess) {
    user = cookie.adminAccess.data
  }

  if (!cookie.accesUser) {
    history.push('/')
    return (<div></div>)
  }

  const logOut = () => {
    removeCookie('accesUser')

    if (cookie.accesUser) {
      let userID = cookie.accesUser.data.user._id


      let conf = {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
      }

      fetch(`${API}/auth/logout/${userID}`, conf)
        .then(res => res.json())
        .then(res => {
          console.log(res);
          socket.disconnect()
          history.push('/')
        })
    }
  }

  return (
    <div className="lobbyContainer">
      <div className="imgBg"></div>

      <HelpBtn country="57" phone="3023180804" className="btnHelp" text="Mesa de ayuda"/>

      {/* ========== Modals ============== */}

      {
        !close && <ModalComplete state={{ close: [close, setClose] }}/>
      }

      <div className="counterContainer">
        <Counter />
      </div>

      <div className="backCont">
        <button className='backBtn close' onClick={logOut}>
          <div className="arrow-left">
            &#10008;
          </div>
          <p>Cerrar Sesión</p>
        </button>
      </div>

      <div className={`dateLogo mar${days.indexOf(localDate.getDate()) >= 0 ? localDate.getDate() : 'zo'}`}></div>

      <div className="inputs">
        <Link className="btnLinks" to="/room" >
          Transmisión en vivo
          <div className="arrow">
            &#10095;
          </div>
        </Link>
        <button className="btnLinks agenda" onClick={e => {setClose(false)}}>
          Programación
          <div className="arrow">
            &#10095;
          </div>
        </button>
        <Link className="btnLinks" to="/memories" >
          Memorias
          <div className="arrow">
            &#10095;
          </div>
        </Link>
      </div>
    </div>
  )
}

export default Lobby
