import React, { useState, useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import Chat from '../../components/room/chat'
import Stream from '../../components/room/stream';
import { API, ws } from '../../config'
import io from "socket.io-client";
import TriviaUser from '../../components/trivia/triviaUser';
import HelpBtn from '../../components/btnHelp/btnHelp'
import './room.css'

const socket = io.connect(ws, { transports: ['websocket'] });

const Room = ({history}) => {
  const [cookie, setCookie, removeCookie] = useCookies({})
  const [iframClient, setIframeClient] = useState('')
  const [triviaStatus, setTriviaStatus] = useState(false)


  useEffect(() => {

    socket.emit('onload', 'onload'); //onConnect

    let conf = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
    };

    fetch(`${API}/iframe/iframeDB`, conf)
      .then(res => res.json())
      .then(res => {
        setIframeClient(res.iframe)
      })

    return () => {
      // Unmount
    }
  }, [])

  let user = null;

  if (cookie.accesUser) {
    user = cookie.accesUser.data
  } else if (cookie.adminAccess) {
    user = cookie.adminAccess.data
  }

  const logOut = () => {
    removeCookie('adminAccess')
    removeCookie('accesUser')

    if (cookie.accesUser) {
      let userID = cookie.accesUser.data.user._id


      let conf = {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
      }

      fetch(`${API}/auth/logout/${userID}`, conf)
        .then(res => res.json())
        .then(res => {
          console.log(res);
          socket.disconnect()
          history.push('/')
        })
    }
  }

  if (!user) {
    history.push('/')
    return (<div></div>)
  }

  return (
    <div className='room'>
      <div className="backCont">
        <Link className='backBtn roomBack' to="/lobby">
          <div className="arrow-left">
            &#10094;
          </div>
          <p>Regresar</p>
        </Link>

        <button className='backBtn close' onClick={logOut}>
          <div className="arrow-left">
            &#10008;
          </div>
          <p>Cerrar Sesión</p>
        </button>
      </div>

      <HelpBtn country="57" phone="3023180804" className="btnHelp" text="Mesa de ayuda"/>

      <div className='roomContent'>
        <div className='roomLeft'>
          <div className='roomVideoContainer'>
            <Stream socket={socket} iframe={iframClient} />
          </div>
        </div>
        <div className='roomRight'>
          <div className="gamecontainer" style={{display: triviaStatus ? 'flex' : 'none'}}>
            <div className='roomTriviaContainer'>
              <TriviaUser userID={user.user._id} state={{ triviaStatus: [triviaStatus, setTriviaStatus] }} />
            </div>
          </div>
          <div className='roomChatContainer'>
            <Chat socket={socket} user={user} />
          </div>
        </div>
      </div>
    </div>

  )

}


export default withRouter(Room);
